import React, {Component} from 'react'
import styled from 'styled-components'
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const BgImageWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    z-index: 2;
    user-select: none;
    
    .image {
        width: 100vw;
        height: 100vh;
    }
`

class BgImage extends Component {
    render() {
        return (
            <BgImageWrapper>
                    <GatsbyImage placeholder="none"
                        image={getImage(this.props.data.FallbackXs)}
                        alt=""
                        title=""
                        className="image d-block d-sm-none"
                    />
                    <GatsbyImage placeholder="none"
                        image={getImage(this.props.data.FallbackSm)}
                        alt=""
                        title=""
                        className="image d-none d-sm-block d-md-none"
                    />
                    <GatsbyImage placeholder="none"
                        image={getImage(this.props.data.FallbackLg)}
                        alt=""
                        title=""
                        className="image d-none d-md-block d-xl-none"
                    />
                    <GatsbyImage placeholder="none"
                        image={getImage(this.props.data.FallbackXl)}
                        alt=""
                        title=""
                        className="image d-none d-xl-block"
                    />
            </BgImageWrapper>
        )
    }
}

export default BgImage